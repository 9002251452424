:root {
	/* --nav-font-color: white; */
	/* --nav-font-color: black; */
	/* --nav-title-size: 2rem; */
	/* --nav-title-weight: 600; */
	/* --nav-font-weight: 600; */
	--nav-bg--color--dark-blue: #282c34; /* dark blue */
	/* --nav-bg-color: #F5F5F0; /1* grayish almost white slightly cream *1/ */
	--nav-bg--color: #E0E0DB; /* light gray, yellowish*/
	/* --nav-bg-color: transparent; */
	/* --body-bg-color: #F5F5F5; */
	--body-bg--color: #F5F5F0; /* grayish almost white slightly cream */
	--body-bg--color: #E0E0DB; /* light gray, yellowish*/
	--body-bg--color: #DFE0E1; /* light gray, bluish*/
	/* --body-bg-color: #38373C; */
	/* --body-bg-color: #F3F3F3; */
	/* --body-bg-color: #e1e1e1; */
	--body-bg--color-accent: #708090; /* dark blue */
	--project-card--font-color: white;

	/* --body-bg-color-accent: #e1e1e1; */
	/* --body-bg-color: #708090; */
	/* --project-card-font-color: black; */

	/* --body-bg-color: #282c34; */
	--body-cli--bg-color: #272622; /* dark blue */



	--algo-color-default: lightgrey;
	--algo-color-selected: indianred;
	--algo-color-used: darkgrey;

	--algo-color-lightblue: #9fc5e8;
	--algo-color-blue: #6495ED;
	--algo-color-pink: #DB7093;
	--algo-color-purple: #8e7cc3;
	--algo-color-green: #9ACD32;
	--algo-color-mint: #90cbaa;
	--algo-color-salad: #68E188;


	--nav-bg--color: transparent;
	--nav-title--size: 1.7rem;
	--nav-title--weight: 500;
	--nav-font--color: black;
	--nav-font--size: 1.1rem;
	--nav-font--weight: 500;
	--nav-icon--size: 1.3rem;
	--nav-padding: 2rem;

	--body--width: 800px;
	--body--min-width: 320px;
	--body-bg--color: #DFE0E1; /* light gray, bluish*/
	--body-font--color: black;
	--body-font--size: 1.2rem;
	--body-line-height: 1.4;

	--settings--icon--size: 1.3rem;
	--settings-font--color: white;

	--go-up-padding: 2rem;

	--projects--filter--animation--speed: 0.3s;
	--projects--filter--animation--speed: 0.7s;
	/* --projects--filter--animation--speed: 1s; */
	/* --projects--filter--animation--speed: 3s; */
}

* {
}

body {
	margin: 0;
	padding: 0;
	min-width: var(--body--min-width);
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: var(--body-font-size);
	background-color: var(--body-bg--color);
	/* background-color: var(--body-cli-bg-color); */
	/* padding-bottom: 100px; */
	line-height: var(--body-line-height);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}


/* ===================== NAVBAR SHARED ===================== */

nav {
	background-color: var(--nav-bg-color);
	height: min-content;
	color: var(--nav-font-color);
}

/* ==================== NAVBAR CENTERED ==================== */

.nav-centured-container {
	background-color: var(--nav-bg--color--dark-blue);
	padding: 2rem;
	margin: 0 auto;
}

.nav-centured--top-section {
	font-size: var(--nav-title--size);
	font-weight: var(--nav-title--weight);
	text-align: center;
}

.nav-centured--webname, .nav-centured--button {
	color: var(--body-bg--color);
	text-decoration: none;
}

.nav-centured--bottom-section {
	font-size: var(--nav-font--size);
	font-weight: var(--nav-font--weight);
	padding: 10px 0 0 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 1rem;
	align-items: flex-start;
}

.nav-centured--icons {
	display: flex;
	align-items: center;
}

.nav-centured--button-icon {
	margin: 0 0 0 15px;
	width: var(--nav-icon--size);
}

/* ==================== NAVBAR MINIMAL ==================== */

.NavbarMinimal {
	padding: var(--nav-padding);
	max-width: 800px;
	margin: 0 auto 0 auto;
}

.nav-minimal--container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.nav-minimal--title-section {
}

.nav-minimal--links-section {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: var(--nav-padding);
}

.nav-minimal--title, .nav-minimal--page-link {
	text-decoration: none;
	color: var(--nav-font--color);
	text-shadow: 0 0 1px gray;
}

.nav-minimal--title {
	font-size: var(--nav-title--size);
	font-weight: var(--nav-title--weight);
}

.nav-minimal--page-link {
	font-size: var(--nav-font--size);
	font-weight: var(--nav-font--weight);
}

.nav-minimal--icons {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.nav-minimal--icon {
	display: flex;
	width: var(--nav-icon--size);
}

/* More menu for Mobile */

.nav-minimal--button--more, .nav-minimal--button--exit {
	padding: 0;
	cursor: pointer;
	border: none;
	width: 2rem;
	height: 2rem;
	background-color: transparent;
}

.nav-minimal--button--more-icon, .nav-minimal--button--exit-icon {
	width: 100%;
}

.nav-minimal--more-section {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--body-bg--color);
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	z-index: 50;
}

.nav-minimal--more-top {
	position: fixed;
	top: var(--nav-padding);
	width: 100%;
	display: flex;
	width: calc(100% - 2 * var(--nav-padding));
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.nav-minimal--more-center {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	align-items: center;
}

.nav-minimal--more-icons {
	position: fixed;
	bottom: 3rem;
	display: flex;
	align-items: center;
	gap: 2rem;
}

@media only screen and (max-width: 720px) {
	.NavbarMinimal {
		margin-bottom: 0rem;
	}
	.nav-minimal--container {
		align-items: center;
	}
}

/* ==================== NAVBAR CLI ==================== */

.NavbarCLI {
	background-color: black;
	height: min-content;
	color: white;
	width: 100%;
}

.nav-cli-container {
	height: min-content;
	color: white;
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
	text-shadow: 0 0 4px white;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 2rem;
}

.nav-cli--top-section {
}

.nav-cli--bottom-section {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 50%;
}

.nav-cli--goto {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	gap: 1rem;
}

.nav-cli--icons {
	display: flex;
	align-items: center;
	justify-content: center;
}

.nav-cli--cursor {
	background-color: white;
	animation: cursor-blink 1s infinite;
	box-shadow: 0 0 4px white;
}

.webname-cli, .nav-cli--button {
	color: white;
	text-decoration: none;
}

.nav-cli--icons {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.nav-cli--button {
}

.nav-cli--button-icon {
	width: 1.5rem;
}

@media only screen and (max-width: 720px) {
	.nav-cli-container {
		flex-direction: column;
		justify-content: space-between;
		padding: 2rem;
	}

	.nav-cli--bottom-section {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
	}

	.nav-cli--icons {
		position: absolute;
		right: -1rem;
		top: -2.5rem;

		display: flex;
		flex-direction: column;
		/* align-items: flex-start; */
		gap: 0.5rem;
	}

	.nav-cli--button-icon {
		width: 1.2rem;
	}
}

/* ==================== PROJECTS PLANK ==================== */

.ProjectsPlank {
	margin: none;
}

.projects-plank--title {
	font-size: 1.5rem;
	font-weight: 550;
	padding: 0;
	margin: 0 0 1rem 0;
	/* text-shadow: 0 0 2px gray; */
}

.cards-list-plank {
    display: flex;
	flex-direction: column;
}

.projects-plank--filter-section {
	max-width: var(--body--width);
	position: relative;
	/* width: auto; */
	margin: 1rem auto 1rem auto;
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	z-index: 20;
}

.projects-plank--button--change-style {
	position: relative;
	border: none;
	border-radius: 50px;
	margin: 0 2rem 1rem 1rem;
	padding: 0;
	background-color: transparent;
	height: 2rem;
	width: 2rem;
	transition-property: background-color, box-shadow;
	transition-timing-function: ease-in-out;
	transition-duration: var(--projects--filter--animation--speed);
	cursor: pointer;
}

.projects-plank--filter {
	padding: 0;
	margin: 0;
	display: flex;
	/* width: 100%; */
	flex-direction: row;
	align-items: center;
	gap: 2rem;
	overflow-x: auto;
}

.projects-plank--filter-reset {
	/* position: absolute; */
	/* top: -0.5rem; */
	/* right: 0rem; */
	font-size: 0.8rem;
	font-weight: 550;
	padding: 0.3rem;
	margin: -1rem 0rem 0rem 1rem;
	right: 1rem;
	/* text-shadow: 0 0 2px gray; */
	line-height: 1;
	border: solid 1px black;
	border-radius: 10px;
	box-shadow: 0 0 2px gray;
	text-align: center;
	/* transform: rotate(-90deg); */
	cursor: default;
}

.projects-plank--filter--list {
	display: flex;
	/* overflow-x: auto; */
	gap: 1rem;
	margin: 0 0 1rem 0;
	padding: 0;
	justify-content: center;
}

.projects-plank--filter--list-item {
	background-color: darkgray;
	color: white;
	padding: 0.5rem;
	border-radius: 8px;
	font-weight: 650;
	font-size: 0.9rem;
	opacity: 0.3;
	transition: opacity .3s linear;
	cursor: pointer;
}

.filter-selected {
	opacity: 1;
}

.projects-plank--filter--list-icon {
	height: 2.2rem;
	opacity: 0.4;
	transition: opacity .3s linear;
	cursor: pointer;
}


.projects-plank--button--change-style--icon-front {
	position: absolute;
	width: 160%;
	top: 0;
	left: 0;
	z-index: 2;
	transition-property: width, top, left;
	transition-timing-function: ease-in-out;
	transition-duration: var(--projects--filter--animation--speed);
}

.projects-plank--button--change-style--icon-back {
	position: absolute;
	width: 100%;
	top: -25%;
	left: -25%;
	z-index: 1;
	transition-property: width, top, left;
	transition-timing-function: ease-in-out;
	transition-duration: var(--projects--filter--animation--speed);
}

@media (pointer: fine) {
	.projects-plank--button--change-style--icon-front {
		animation: putButtonFrontTouch var(--projects--filter--animation--speed);
	}

	.projects-plank--button--change-style--icon-back {
		animation: putButtonBackTouch var(--projects--filter--animation--speed);
	}

	/* .projects-plank--button--change-style:hover .projects-plank--button--change-style--icon-front { */
	/* 	/1* width: 130%; *1/ */
	/* 	/1* top: 25%; *1/ */
	/* 	/1* left: 25%; *1/ */
	/* 	animation: putButtonFrontHover 0.2s infinite; */
	/* } */

	/* .projects-plank--button--change-style:hover .projects-plank--button--change-style--icon-back { */
	/* 	/1* width: 130%; *1/ */
	/* 	/1* top: -70%; *1/ */
	/* 	/1* left: -70%; *1/ */
	/* 	animation: putButtonBackHover 0.2s infinite; */
	/* } */

	.projects-plank--button--change-style:hover {
		/* background-color: rgba(20, 20, 0, 0.2); */
		/* box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.7), 0 20px 40px 0 rgba(0, 0, 0, 0.5); */
	}

	.projects-plank--filter--list-item:hover {
		opacity: 1;
	}

	.projects-plank--filter--list-icon:hover {
		opacity: 1;
	}
}

@media (pointer: coarse) {
	.projects-plank--button--change-style--icon-front {
		animation: putButtonFrontTouch var(--projects--filter--animation--speed);
	}

	.projects-plank--button--change-style--icon-back {
		animation: putButtonBackTouch var(--projects--filter--animation--speed);
	}

}

@keyframes putButtonFrontHover {
	from {
	}
	to {
	}
}

@keyframes putButtonFrontTouch {
	0% {
		width: 100%;
		top: -25%;
		left: -25%;
	}
	20% {
		width: 115%;
		top: -70%;
		left: -70%;
	}
	100% {
		width: 160%;
		top: 0;
		left: 0;
	}
}

@keyframes putButtonBackTouch {
	0% {
		width: 160%;
		top: 0;
		left: 0;
	}
	20% {
		width: 115%;
		top: 45%;
		left: 45%;
		z-index: 1;
	}
	100% {
		width: 100%;
		top: -25%;
		left: -25%;
		z-index: 1;
	}
}

@media only screen and (max-width: 720px) {
	.ProjectsPlank {
	}

	.cards-list-plank {
	}

	.projects-plank--button--change-style {
		margin: 1rem 0 2rem 2rem;
	}

	.projects-plank--filter-section {
		position: relative;
		flex-direction: column;
		margin: 0 0 1rem 0;
		align-items: flex-start;
	}

	.projects-plank--filter {
		width: 100%;
	}

	.projects-plank--filter--list {
		padding: 0 1rem 0 1rem;
	}

	.projects-plank--filter-reset {
		position: absolute;
		top: 0.5rem;
		rigth: 3rem;
		width: 4rem;
		margin: 0 0.5rem 0 0.5rem;
		font-size: 1rem;
	}
}

/* ==================== PROJECTS EXPLICIT ==================== */

.ProjectsExplicit {
	margin: 0 auto 3rem auto;
	max-width: var(--body--width);
}

.projects-explicit--title {
	font-size: 1.5rem;
	font-weight: 550;
	padding: 0 0 1rem 3rem;
	padding: 0 0 1rem 0rem;
	text-shadow: 0 0 2px gray;
}

.cards-list-explicit {
    display: flex;
	flex-direction: column;
    gap: 2rem;
}

@media only screen and (max-width: 720px) {
	.ProjectsExplicit {
		margin-bottom: 1rem;
	}

	.cards-list-explicit {
		flex-direction: row;
		flex-wrap: nowrap;
		overflow-x: auto;
		justify-content: flex-start;
		gap: 2rem;
		padding: 0 8% 0 8%;
	}
}

/* ==================== PROJECTS COMPACT ==================== */

.ProjectsCompact {
	margin: auto;
	max-width: var(--body--width);
}

.cards-list-compact {
	/* margin: auto; */
    display: flex;
	flex-direction: column;
    /* flex-wrap: nowrap; */
    gap: 2rem;
	padding-top: 5%;
    /* overflow-x: auto; */
	/* justify-content: flex-start; */
}

@media only screen and (max-width: 720px) {
	.cards-list-compact {
		flex-direction: row;
		flex-wrap: nowrap;
		overflow-x: auto;
		justify-content: flex-start;
		gap: 2rem;
		/* margin: 0; */
		padding: 8%;
		/* padding-top: 0; */
	}
}

/* ===================== ???????????? PROJECTS CARD COMPACT ==================== */

@media only screen and (max-width: 720px) {
	.Project {
		display: flex;
		/* flex-wrap: nowrap; */
	}

	.cards-list {
		/* flex-direction: column; */
		/* flex-wrap: nowrap; */
		/* overflow-y: visible; */
		/* justify-content: flex-start; */
		/* gap: 2rem; */
		/* margin: 0; */
		/* padding: 8%; */
		/* padding-top: 0; */
		display: inline;
		padding: 2rem;
	}
}

/* ==================== CARD PLANK ==================== */

.CardPlank {
    font-size: 1.5rem;
    display: flex;
    flex-direction: row;
	height: 300px;
}

.card-plank--image-section {
	height: 100%;
	width: 50%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.card-plank--image-subsection {
	height: 100%;
	width: 500px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.card-plank--image {
	max-height: 90%;
	max-width: 80%;
}

.card-plank--content-section {
	display: flex;
	flex-direction: column;
	width: 50%;
	align-content: space-around;
}

.card-plank--top-section {
	height: 35%;
	padding: 10px;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.card-plank--title {
	font-size: 1.8rem;
	font-weight: 500;
}

.card-plank--description {
	font-size: 1.25rem;
}

.card-plank--bottom-section {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.card-plank--tech {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: 10px;
	padding: 10px;
}

.card-plank--tech-item {
	background-color: black;
	padding: 0.6rem;
	border-radius: 8px;
	font-weight: 600;
	font-size: 1rem;
}

.card-plank--tech-item--image {
	height: 3rem;
}

.card-plank--links {
	height: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	padding: 1rem;
	gap: 1.5rem;
}

.card-plank--link-icon {
	background-color: white;
	padding: 8px;
	border-radius: 10px;
	width: 3rem;
}

@media only screen and (max-width: 720px) {
	.CardPlank {
		flex-direction: column;
		font-size: 1rem;
		min-width: 90%;
		margin: 0;
		height: 100%;
	}

	.card-plank--title {
		font-size: 1.6rem;
	}

	.card-plank--image-section {
		width: 100%;
		height: 100%;
	}

	.card-plank--image-subsection {
		min-width: 90%;
		margin: auto;

		padding: 1.5rem 0 0 0;
		border-radius: 25px 0 0 25px;
	}

	.card-plank--image {
		width: 100%;

	}

	.card-plank--content-section {
		height: 50%;
		width: 100%;
	}

	.card-plank--description {
		font-size: 1.2rem;
	}

	.card-plank--tech-item--image {
		height: 3rem;
	}

	.card-plank--links {
		justify-content: center;
		gap: 1.5rem;
	}
}

@media (pointer: fine) {
	.card-plank--link-icon {
		opacity: 0.4;
		transition: opacity 0.5s;
	}

	.card-plank--link-icon:hover {
		opacity: 1;
	}
}

@media (pointer: coarse) {
	.card-plank--link-icon {
		/* opacity: 0.9; */
		/* transition: opacity 0.8s, box-shadow 0.8s; */
		transition: box-shadow 0.8s;
	}

	.card-plank--link-icon:hover {
		/* opacity: 1; */
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	}
}

/* ==================== CARD EXPLICIT ROUND CORNERS ==================== */

.Card {
	background-color: var(--body-bg-color-accent);
	/* margin: 20px; */
    /* max-width: var(--body-width); */
	/* height: 400px; */
	/* height: 200px; */
    font-size: 1.3rem;
    display: flex;
    flex-direction: row;
	color: var(--project-card-font-color);
	border-radius: 25px;
	/* box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.card--image-section {
	height: 100%;
	width: 50%;
	display: flex;
	align-items: center;
	padding: 3%;
	border-radius: 25px 0 0 25px;
}

.card--image {
	max-height: 100%;
	max-width: 100%;
}

.card--content-section {
	display: flex;
	flex-direction: column;
	width: 50%;
}

.card--top-section {
	padding: 10px;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.card--title {
	font-size: 1.8rem;
	font-weight: 500;
}

.card--discription {
	font-size: 1rem;
}

.card--bottom-section {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.card--tech {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: 10px;
	padding: 10px;
}

.card--tech-item {
	background-color: black;
	padding: 0.6rem;
	border-radius: 8px;
	font-weight: 600;
	/* font-size: 1.3rem; */
	/* font-size: 1rem; */
}

.card--tech-item-image {
	/* background-color: white; */
	padding: 2px;
	/* border-radius: 15px; */
	/* width: 3rem; */
	height: 3rem;
}

.card--links {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding: 10px;
	gap: 20px;
}

.card--icon {
	background-color: white;
	padding: 8px;
	border-radius: 10px;
	width: 3rem;
}

@media only screen and (max-width: 720px) {
	.Card {
		flex-direction: column;
		font-size: 1rem;
		min-width: 90%;
		margin: 0;
		height: 100%;
	}

	.card--title {
		font-size: 1.6rem;
	}

	.card--image-section {
		min-width: 90%;
		margin: auto;

		padding: 1.5rem 0 0 0;
		border-radius: 25px 0 0 25px;
	}

	.card--image {
		width: 100%;

	}

	.card--content-section {
		height: 50%;
		width: 100%;
	}

	.card--tech-item-image {
		height: 2.4rem;
	}
}

@media (pointer: fine) {
	.card--icon {
		opacity: 0.4;
		transition: opacity 0.5s;
	}

	.card--icon:hover {
		opacity: 1;
	}
}

@media (pointer: coarse) {
	.card--icon {
		/* opacity: 0.9; */
		/* transition: opacity 0.8s, box-shadow 0.8s; */
		transition: box-shadow 0.8s;
	}

	.card--icon:hover {
		/* opacity: 1; */
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	}
}

/* ==================== CARD COMPACT ==================== */

.card-compact {
	background-color: var(--body-bg-color-accent);
	/* margin: 20px; */
    /* max-width: var(--body-width); */
	/* height: 400px; */
	/* height: 200px; */
    font-size: 1.3rem;
    /* display: flex; */
    /* flex-direction: row; */
	color: var(--project-card-font-color);
	border-radius: 25px;
	/* box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.card-compact--image-section {
	height: 100%;
	width: 50%;
	display: flex;
	align-items: center;
	padding: 3%;
	border-radius: 25px 0 0 25px;
}

.card-compact--image {
	max-height: 100%;
	max-width: 100%;
}

.card-compact--content-section {
	display: flex;
	flex-direction: column;
	width: 50%;
}

.card-compact--top-section {
	padding: 10px;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.card-compact--title {
	font-size: 1.8rem;
	font-weight: 500;
}

.card-compact--discription {
	font-size: 1rem;
}

.card-compact--bottom-section {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.card-compact--tech {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: 10px;
	padding: 10px;
}

.card-compact--tech-item {
	background-color: black;
	padding: 0.6rem;
	border-radius: 8px;
	font-weight: 600;
	/* font-size: 1.3rem; */
	/* font-size: 1rem; */
}

.card-compact--tech-item-image {
	/* background-color: white; */
	padding: 2px;
	/* border-radius: 15px; */
	/* width: 3rem; */
	height: 3rem;
}

.card-compact--links {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding: 10px;
	gap: 20px;
}

.card-compact--icon {
	background-color: white;
	padding: 8px;
	border-radius: 10px;
	width: 3rem;
}

@media only screen and (max-width: 720px) {
	.card-compact {
		/* flex-direction: column; */
		position: relative;
		font-size: 1rem;
		min-width: 90%;
		margin: 0;
		/* padding: 0; */
		height: 100%;
	}

	.card-compact--image-section {
		min-width: 90%;
		margin: auto;
		padding: 1.5rem 0 1.5rem 0;
		/* border-radius: 25px 0 0 25px; */
	}

	.card-compact--image {
		width: 100%;

	}

	.card-compact--content-section {
		position: absolute;
		top: 0;
		left: 0;
		background-color:rgba(10, 10, 10, 0.2);
		backdrop-filter: blur(5px);
		/* height: calc(100% - 2rem); */
		/* width: calc(100% - 2rem); */
		height: 100%;
		width: 100%;
		border-radius: 25px;
		/* padding: 1rem; */
	}

	.card-compact--tech-item-image {
		height: 2.4rem;
	}

	.card-compact--top-section {
		/* padding: 10px; */
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}

	.card-compact--title {
		font-size: 1.8rem;
		font-weight: 500;
	}

	.card-compact--discription {
		font-size: 1rem;
	}

	.card-compact--bottom-section {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.card-compact--tech {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		gap: 10px;
		padding: 10px;
	}

	.card-compact--tech-item {
		background-color: black;
		padding: 0.6rem;
		border-radius: 8px;
		font-weight: 600;
		/* font-size: 1.3rem; */
		/* font-size: 1rem; */
	}

	.card-compact--tech-item-image {
		/* background-color: white; */
		padding: 2px;
		/* border-radius: 15px; */
		/* width: 3rem; */
		height: 3rem;
	}

	.card-compact--links {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		padding: 10px;
		gap: 20px;
	}

	.card-compact--icon {
		background-color: white;
		padding: 8px;
		border-radius: 10px;
		width: 3rem;
	}
}

@media (pointer: fine) {
	.card-compact--icon {
		opacity: 0.4;
		transition: opacity 0.5s;
	}

	.card-compact--icon:hover {
		opacity: 1;
	}
}

@media (pointer: coarse) {
	.card-compact--icon {
		/* opacity: 0.9; */
		/* transition: opacity 0.8s, box-shadow 0.8s; */
		transition: box-shadow 0.8s;
	}

	.card-compact--icon:hover {
		/* opacity: 1; */
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	}
}

/* ==================== ALGORITHMS ==================== */

.Algorithms {
	margin: 2rem auto 0 auto;
	max-width: var(--body--width);
	display: flex;
	flex-direction: column;
}

.algorithms--title {
	padding: 0;
	margin: 0 0 2rem 0;
	font-size: 1.5rem;
	font-weight: 550;
	text-shadow: 0 0 2px gray;
}

.algorithms--button-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.algorithms--button-active, .algorithms--button-idle {
	margin: 0px;
	border: 0;
	font-size: 1.5rem;
	color: black;
	font-weight: 600;
	border-radius: 15px 15px 0 0;
	padding: 1rem 1.5rem 0.5rem 1.5rem;
}

.algorithms--button-active {
	background-color: var(--body-bg--color);
	box-shadow: 0 8px 1px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 5px rgba(0, 0, 0, 0.19);
	clip-path: inset(-20px -20px 0 -20px);
	z-index: 5;
}

.algorithms--button-idle {
	background-color: darkgray;
	transition: background-color 0.4s ease;
}

.algorithms--button-idle:hover {
	background-color: lightgray;
}

.algorithms--container {
	border-top: 5px solid var(--body-bg-color);
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), -20px 8px 10px 10px rgba(0, 0, 0, 0.19);
	clip-path: inset(-20px 0 0 0);
	z-index: 1;
	padding-bottom: 5rem;
}

@media only screen and (max-width: 720px) {
	.Algorithms {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}


/* =============== ALGORITHMS SHARED ================ */

.algorithms--description {
	line-height: 1.6;
	/* text-align: justify; */
	font-family: monospace;
}

.algorithms--description-source {
	text-decoration: none;
	font-family: monospace;
	font-size: 1.2rem;
	color: indianred;
	font-weight: 600;
}


/* ==================== SORTING ==================== */

.sorting-graph-container {
	margin-top: 1rem;
	margin-bottom: 1rem;
	/* padding: 0 8px 0 8px; */
	/* min-height: 400px; */
	/* max-height: 400px; */
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	gap: 2px;
	height: 400px;
}

.bar {
	flex-grow: 1;
	max-height: 400px;
	transition: height 0.2s ease-out;
}

.controls {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.section--sort {
	margin: 0px 0px 10px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 5px;
	justify-content: space-between;
}

.section--generate {
	/* max-width: var(--body-width); */
	display: flex;
	align-items: center;
	background-color: lightgray;
	padding: 10px
}

.section--description {
	max-width: var(--body--width);
}

.button--algo {
	background-color: darkgray;
	margin: 0px;
	border: 0;
	border-radius: 5px;
	padding: 8px 10px;
	font-size: 1rem;
	color: white;
	font-family: monospace;
	font-weight: 600;
	font-size: 1rem;
}

.button--algo:hover {
	opacity: 0.7;
}

.button--algo:active {
	background-color: indianred;
}

.button-selected {
	background: indianred;
}

.button-selected:hover {
	background: indianred;
}

.button--disabled {
	background-color: darkgray;
	opacity: 0.3;
	margin: 0px;
	border: 0;
	border-radius: 5px;
	padding: 8px 10px;
	font-size: 1rem;
	color: white;
}

.button--refresh {
	padding: 5px 10px;
	border-radius: 5px;
	width: 2rem;
	height: 2rem;
	background-image: url('./images/refresh-white.png');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 65%;
}

.slider-container {
	width: 100%;
	padding: 0 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.slider {
	-webkit-appearance: none;
	width: 90%;
	height: 10px;
	border-radius: 5px;
	background: white;
	outline: none;
	opacity: 0.7;
	-webkit-transition: .2s;
	transition: opacity .2s;
}

.slider:hover {
	opacity: 1;
}

.slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	border-style: none;
	background: #008B8B;
	cursor: pointer;
}

.slider::-moz-range-thumb {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	border-style: none;
	background: #008B8B;
	cursor: pointer;
}

.slider-icons {
	margin: 0;
	width: 24px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
}


/* ================== PATHFINDING ================== */
/* .App-header { */
/*   background-color: #282c34; */
/*   min-height: 100vh; */
/*   display: flex; */
/*   flex-direction: column; */
/*   align-items: center; */
/*   justify-content: center; */
/*   font-size: calc(10px + 2vmin); */
/*   color: white; */
/* } */

/* .App-link { */
/*   color: #09d3ac; */
/* } */

.pathfinding-grid-container {
	margin: 16px 0;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	gap: 1px;
	align-items: center;
}

.pathfinding-grid-row {
	/* max-width: 100%; */
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	gap: 1px;
}

.button--path {
/* 	background-color: var(--algo-color-used); */
/* 	margin: 0px; */
/* 	border: 0; */
/* 	border-radius: 5px; */
/* 	padding: 8px 10px; */
/* 	font-size: 1rem; */
/* 	color: white; */
	margin: 1rem 0 0 0;
/* 	font-weight: 500; */
}

/* .button--path:hover { */
/* 	opacity: 0.7; */
/* } */

/* .button--path:active { */
/* 	background-color: var(--algo-color-selected); */
/* } */


/* =============== PATHFINDING NODE ================ */

.node {
	min-width: 25px;
	min-height: 25px;
	outline: 1px solid var(--body-bg-color);
	background-color: var(--algo-color-default);
	/* outline: 1px solid var(--algo-color-default); */
	/* background-color: var(--body-bg-color); */
}

.node-finish {
	background-color: var(--algo-color-pink);
}

.node-start {
	background-color: var(--algo-color-blue);
	
}

.node-visited {
	animation-name: visited;
	animation-duration: 1s;
	animation-timing-function: ease-out;
	animation-delay: 0;
	animation-direction: alternate;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	animation-play-state: running;
}

@keyframes visited {
	0% {
		transform: scale(0.1);
		background-color: var(--algo-color-lightblue);
		opacity: 0;
	}
	50% {
		background-color: var(--algo-color-blue);
	}
	75% {
		transform: scale(1.2);
		opacity: 1;
	}
	100% {
		/* transform: scale(1); */
		transform: scale(1.1);
		background-color: var(--algo-color-purple);
	}
}

.node-wall {
	background-color: var(--body-bg-color);
	/* background-color: var(--algo-color-default); */
}

.node-shortest-path {
	animation-name: shortestPath;
	animation-duration: 1s;
	animation-timing-function: ease-out;
	animation-delay: 0;
	animation-direction: alternate;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	animation-play-state: running;
}

@keyframes shortestPath {
	0% {
		transform: scale(0.8);
		background-color: var(--algo-color-salad);
	}
	25% {
		transform: scale(1.4);
		background-color: var(--algo-color-green);
	}
	50% {
		background-color: var(--algo-color-mint);
	}
	100% {
		transform: scale(1.1);
		background-color: var(--algo-color-mint);
	}
}


/* ==================== ARCHIVE ==================== */

.Archive {
	width: var(--body--width);
	margin: 0 auto;

}

.archive-container {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding-left: 20px;
}

.archive--title {
	margin: 1rem 0 0 0;
	padding: 0;
	font-size: 1.5rem;
	font-weight: 550;
	text-shadow: 0 0 2px gray;
}

.archive--link {
	text-decoration: none;
	font-size: 1.5rem;
	color: black;
	font-weight: 500;
}

.archive--link:visited {
	color: darkgray;
}

.archive--li {
	padding-left: 3rem;
}


/* ==================== SETTINGS ==================== */

.settings--button--enter, .settings--button--exit {
	border: none;
	padding: 0;
	background-color: transparent;
}

.settings--button--enter {
	width: var(--settings--icon--size);
	position: absolute;
	top: 0.2rem;
	left: 0.2rem;
	opacity: 0.03;
	transition: opacity 0.5s ease-in-out;
}

.settings--button--exit {
	width: var(--nav-title--size);
	position: absolute;
	/* position: fixed; */
	top: 1rem;
	right: 1rem;
	cursor: pointer;
}

.settings--button--icon {
	width: 100%;
}

.settings--button--enter:hover {
    animation: settings-icon-spin infinite 10s linear;
	opacity: 0.5;
}

@keyframes settings-icon-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.settings {
	position: fixed;
	top: calc(50% - 250px);
	left: calc(50% - 250px);
	min-width: 500px;
	min-height: 500px;
	background-color:rgba(100, 100, 100, 0.9);
	color: var(--settings-font--color);
	/* display: flex; */
	/* justify-content: center; */
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 8px 0 rgba(0, 0, 0, 0.19);
	z-index: 91;
}

.settings-rim {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color:rgba(100, 100, 100, 0.2);
	/* background-color: rgba(255, 255, 255, .15); */  
	backdrop-filter: blur(5px);
	z-index: 90;

}

.settings-content-container {
	height: 500px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.settings-content--title {
	margin: 0;

}

.settings-content--text {
	padding: 2rem;

}

.settings-content--img {
	max-width: 3rem;

}

.settings-content--wip-scroller {
	position: fixed;
}

@media only screen and (max-width: 720px) {
	.settings {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.settings-content-container {
		width: 100vw;
		height: 100vh;
	}

	.settings--button--exit {
		position: fixed;
	}
}

/* ==================== GO UP BUTTON ==================== */

.go-up-container {
	position: relative;
}

.go-up--button {
	background-color:rgba(100, 100, 100, 0.7);
	border: none;
	margin: 0;
	padding: 0.3rem;
	border-radius: 8px;
	width: 2.4rem;
	position: fixed;
	bottom: var(--go-up-padding);
	right: var(--go-up-padding);
	opacity: 0.5;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: opacity 0.5s ease-in-out;
	z-index: 40;
}

.go-up--button--icon {
	width: 100%;
}

@media (pointer: fine) {
	.go-up--button:hover {
		animation: go-up-appear infinite 2s linear;
		opacity: 1;
	}
}

@keyframes go-up-appear {
	0%{
		transform: translateY(0rem);
	}
	25%{
		transform: translateY(-0.25rem);
	}
	75%{
		transform: translateY(0.25rem);
	}
	100%{
		transform: translateY(0rem);
	}
}


/* ==================== ABOUT / CLI ==================== */

.about--container {
	background-color: black;
	color: white;
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
	/* font-size: 2rem; */
	text-shadow: 0 0 4px white;
	padding: 25px 50px;
	white-space: wrap;
	/* width: 100%; */

}

.about--cursor {
	background-color: white;
	/* padidng-right: 0.4rem; */
	/* border-radius: 1px; */
	animation: cursor-blink 1s infinite;
	box-shadow: 0 0 4px white;
}

@keyframes cursor-blink {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	75% {
		opacity: 1;
	}
}

.about--intro {
	/* animation: about-typing 4s steps(100, end); */
	/* overflow-x: hidden; */
}

/* @keyframes about-typing { */
/* 	from { */
/* 		width: 0; */
/* 	} */
/* } */

/* p:nth-child(1) { */
/* 	animation: typingtwo 1s steps(0, end); */
/* } */

/* @keyframes typingtwo { */
/* 	0% { */
/* 		width: 0; */
/* 	} */
/* 	50% { */
/* 		width: 0%; */
/* 	} */
/* 	100% { */
/* 		width: 100%; */
/* 	} */
/* } */


/* ==================== CONTENT ==================== */

.content-container {
	max-width: var(--body--width);
	margin: auto;
	line-height: 1.6;
	text-align: justify;
}

.content--heading {
}

.content--text {
	padding: 0rem 4rem;
	margin: 0;
}

hr {
	margin: 3rem 0 1rem 0;
	padding: 0;
	background-color: lightgray;
	border: none;
	height: 2px;
	max-width: var(--body--width)%;
}

@media only screen and (max-width: 720px) {
	.content--text {
		padding: 0rem 1rem;
	}
}
/* ==================== ETC ==================== */

.yellow-neon-sign {
	color: yellow;
	font-size: 1.5rem;
	font-weight: 500;
	text-shadow: 0 0 18px white, 0 0 6px yellow;
	font-family: monospace;
	margin: auto;
	padding: 0;
	/* text-align: center; */
	animation: construction-sign--noise 1s infinite;
	overflow-x: auto;
}

@keyframes construction-sign--noise {
	0% {
		text-shadow: 0 0 16px white, 0 0 4px yellow;
	}
	25% {
		text-shadow: 0 0 12px white, 0 0 4px yellow;
	}
	50% {
		text-shadow: 0 0 8px white, 0 0 4px yellow;
	}
	75% {
		text-shadow: 0 0 4px white, 0 0 1px yellow;
	}
	85% {
		text-shadow: 0 0 10px white, 0 0 2px yellow;
	}
	90% {
		text-shadow: 0 0 18px white, 0 0 6px yellow;
	}
	100% {
		text-shadow: 0 0 14px white, 0 0 6px yellow;
	}
}
